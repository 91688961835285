@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Results/view/results/css-components/variables';

@mixin hotelResultTile($redesignResults: false) {
    margin: 0 $global-spacing-small $global-spacing;
    border-radius: $global-radius;
    box-shadow: 0 1px 8px rgba($darkSideOfTheForce, 0.1);

    @if $redesignResults {
        margin: 0 $global-spacing-small;
        border-radius: unset;
        box-shadow: unset;
    }
}
