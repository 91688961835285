$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Results/view/results/css-components/mixins';

.easterDiscountTeaserMobile {
    position: relative;
    display: flex;
    gap: $global-spacing-small;
    padding: $global-spacing-smedium 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: $global-spacing-small;
}

.headline {
    font-size: 16px;
    font-weight: bold;
}

.subHeadline {
    font-size: 14px;
}

.eggImage {
    flex: 1 0 33%;
    display: flex;
    align-items: center;
}

.listTile {
    @include hotelResultTile;

    padding: 0 10px;
    background-color: $sophieWhite;
}
